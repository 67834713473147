<template>
    <div class="pa-5 confirmModal">
        <div class="d-flex justify-center py-5">
            <img
                width="100"
                height="100"
                src="@/assets/img/global/warning.svg"
                alt="warning"
            />
        </div>

        <v-card-title class="d-flex justify-center pa-2">{{
            $t('service-info:sendMailInfo')
        }}</v-card-title>
        <v-card-text class="d-flex justify-center pa-2">{{
            $t('service-info:sendMailConfirm')
        }}</v-card-text>
    </div>
</template>
<script>
export default {
    i18nOptions: { namespaces: ['global'] },
}
</script>

<template>
    <v-container>
        <Header
            :registryStatus="getOperationLockStatuses"
            :recordIndex="getOperationLockDetails.index"
            :registryTitle="getOperationLockRegistry.name"
            :registryUrl="'operation-lock'"
            :actualStatus="getOperationLockDetails.status"
            :avatarData="getOperationLockDetails.createdBy"
            @status-changed="changeStatus"
        ></Header>
        <Main :items="items" class="p-relative">
            <v-tab-item
                v-for="item in items"
                :key="item.id"
                slot="tabItems"
                :id="item.id"
            >
                <component :is="item.component"></component>
            </v-tab-item>
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('operation-lock:edition')"
                    :open="open"
                    :height="'560'"
                    v-on:close="closeModal()"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        :action="'edit'"
                        slot="buttons"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <ButtonsInfo @openConfirmModal="openConfirmModal" />
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                v-if="edit()"
                                small
                                @click="editOperationLock()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('operation-lock:edit') }}</span>
                    </v-tooltip>
                </div>
                <ConfirmModal
                    :openConfirm="openConfirm"
                    @close-confirm="closeConfirmModal"
                >
                    <div slot="main"><ConfirmMailContent /></div>
                    <div slot="buttons">
                        <div class="d-flex justify-center">
                            <v-btn
                                color="rgb(117, 117, 117)"
                                width="110"
                                height="40"
                                @click="closeConfirmModal()"
                                class="white--text mx-2"
                                >{{ $t('global:cancel') }}</v-btn
                            >
                            <v-btn
                                width="110"
                                height="40"
                                color="#445d75"
                                class="white--text mx-2"
                                @click="sendMessage()"
                                >{{ $t('global:send') }}</v-btn
                            >
                        </div>
                    </div>
                </ConfirmModal>
            </div>
        </Main>
    </v-container>
</template>

<script>
import store from './../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Information from '@/components/Registries/Operation-lock/Details/Information.vue'
import AEContent from './../../../components/Registries/Operation-lock/Modal/Content.vue'
import Buttons from './../../../components/Registries/Operation-lock/Modal/Buttons'
import ButtonsInfo from '../../../components/Registries/Operation-lock/Details/Buttons.vue'
import ConfirmMailContent from '../../../components/Registries/Operation-lock/ConfirmModal/ConfirmMailModal.vue'
export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        Information,
        AEContent,
        Buttons,
        ButtonsInfo,
        ConfirmMailContent,
    },
    data() {
        return {
            open: false,
            openConfirm: false,
            items: [],
        }
    },
    computed: {
        ...mapGetters([
            'getOperationLockStatuses',
            'getOperationLockRegistry',
            'getOperationLockDetails',
        ]),
    },
    methods: {
        ...mapMutations([
            'setOperationLockDetails',
            'setOperationLockStatuses',
            'setOperationLockRegistry',
            'setOperationLockModal',
            'clearOperationLockErrors',
            'clearRepresentativesInput',
            'clearOperationLockModal',
        ]),
        ...mapActions([
            'fetchOperationLock',
            'updateOperationLockStatus',
            'fetchUsers',
            'sendOperationLockInfo',
        ]),
        changeStatus(status) {
            this.updateOperationLockStatus({
                status,
                id: this.getOperationLockDetails._id,
            })
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
        async editOperationLock() {
            this.open = true
            await this.fetchUsers({
                select: 'name lastname mail status',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })
            this.clearOperationLockErrors()
            this.setOperationLockModal()
        },
        closeModal() {
            this.open = false
            this.clearOperationLockErrors()
            this.clearRepresentativesInput()
            this.clearOperationLockModal()
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        openConfirmModal() {
            this.openConfirm = true
        },
        async sendMessage() {
            this.openConfirm = false
            this.sendOperationLockInfo()
        },
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchOperationLock', { id: to.params.id, next })
    },
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"3","xl":"3","lg":"3","md":"5","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"},[_vm._v(" "+_vm._s(_vm.$get( _vm.getOperationLockDetails, 'contractor.name', _vm.$t('global:empty') ))+" ")]),_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t('operation-lock:reason')}`)+":")]),_vm._v(" "+_vm._s(_vm.$get( _vm.getOperationLockDetails, 'reason.field', '' ))+" ")]),_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t('operation-lock:lastModification')}`)+":")]),_vm._v(" "+_vm._s(_vm.getOperationLockDetails.updatedAt ? _vm.$moment( _vm.getOperationLockDetails.updatedAt ).format(`DD/MM/YYYY`) : _vm.$t('global:empty')))])])],1)],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"6","xl":"6","lg":"8","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.$get(
                                _vm.getOperationLockDetails,
                                'contractor.name',
                                ''
                            ),"label":_vm.$t('operation-lock:contractor')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticStyle:{"width":"60%"},attrs:{"readonly":"","value":_vm.getOperationLockDetails.createdAt
                                ? _vm.$moment(
                                      _vm.getOperationLockDetails.createdAt
                                  ).format('DD-MM-YYYY HH:mm')
                                : _vm.$t('global:empty'),"label":_vm.$t('operation-lock:createdAt')}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticStyle:{"width":"60%","float":"right"},attrs:{"readonly":"","value":_vm.getOperationLockDetails.updatedAt
                                ? _vm.$moment(
                                      _vm.getOperationLockDetails.updatedAt
                                  ).format('DD-MM-YYYY HH:mm')
                                : _vm.$t('global:empty'),"label":_vm.$t('operation-lock:lastModification')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"readonly":"","append-icon":"","items":[_vm.getOperationLockDetails.reason],"dense":"","label":_vm.$t('operation-lock:reason'),"return-object":"","item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(data.item.backgroundColor)?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item.backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                            ? data.item.textColor
                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(data.item.backgroundColor)?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item.backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                            ? data.item.textColor
                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(_vm.getOperationLockDetails.reason),callback:function ($$v) {_vm.$set(_vm.getOperationLockDetails, "reason", $$v)},expression:"getOperationLockDetails.reason"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticStyle:{"width":"60%","float":"right"},attrs:{"readonly":"","value":_vm.responsibles(),"label":_vm.$t('operation-lock:responsible')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-15",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"readonly":"","value":_vm.getInformationTo(_vm.getOperationLockDetails),"label":_vm.$t('service-info:infoTo') + ':',"no-resize":"","rows":"1","auto-grow":""}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-15",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"readonly":"","value":_vm.$get(_vm.getOperationLockDetails, 'description', ''),"label":_vm.$t('operation-lock:description'),"no-resize":"","rows":"1","auto-grow":""}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            class="font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"
                        >
                            {{
                                $get(
                                    getOperationLockDetails,
                                    'contractor.name',
                                    $t('global:empty')
                                )
                            }}
                        </p>
                        <span
                            ><b>{{ `${$t('operation-lock:reason')}` }}:</b>
                            {{
                                $get(
                                    getOperationLockDetails,
                                    'reason.field',
                                    ''
                                )
                            }}
                        </span>
                        <span
                            ><b
                                >{{
                                    `${$t('operation-lock:lastModification')}`
                                }}:</b
                            >
                            {{
                                getOperationLockDetails.updatedAt
                                    ? $moment(
                                          getOperationLockDetails.updatedAt
                                      ).format(`DD/MM/YYYY`)
                                    : $t('global:empty')
                            }}</span
                        >
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="
                                $get(
                                    getOperationLockDetails,
                                    'contractor.name',
                                    ''
                                )
                            "
                            :label="$t('operation-lock:contractor')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6"
                        ><v-text-field
                            style="width: 60%;"
                            readonly
                            :value="
                                getOperationLockDetails.createdAt
                                    ? $moment(
                                          getOperationLockDetails.createdAt
                                      ).format('DD-MM-YYYY HH:mm')
                                    : $t('global:empty')
                            "
                            :label="$t('operation-lock:createdAt')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6"
                        ><v-text-field
                            style="width: 60%; float: right;"
                            readonly
                            :value="
                                getOperationLockDetails.updatedAt
                                    ? $moment(
                                          getOperationLockDetails.updatedAt
                                      ).format('DD-MM-YYYY HH:mm')
                                    : $t('global:empty')
                            "
                            :label="$t('operation-lock:lastModification')"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6">
                        <v-select
                            readonly
                            append-icon=""
                            v-model="getOperationLockDetails.reason"
                            :items="[getOperationLockDetails.reason]"
                            dense
                            :label="$t('operation-lock:reason')"
                            return-object
                            item-value="field"
                            ><template v-slot:item="data">
                                <span v-if="data.item.backgroundColor">
                                    <v-chip
                                        class="mx-2"
                                        :color="data.item.backgroundColor"
                                        small
                                        label
                                        :text-color="
                                            data.item.textColor
                                                ? data.item.textColor
                                                : '#4d4d4d'
                                        "
                                    >
                                        {{ data.item.field }}
                                    </v-chip>
                                </span>
                                <span class="truncate-list" v-else>
                                    {{ data.item.field }}
                                </span>
                            </template>
                            <template v-slot:selection="data">
                                <span v-if="data.item.backgroundColor">
                                    <v-chip
                                        class="mx-2"
                                        :color="data.item.backgroundColor"
                                        small
                                        label
                                        :text-color="
                                            data.item.textColor
                                                ? data.item.textColor
                                                : '#4d4d4d'
                                        "
                                    >
                                        {{ data.item.field }}
                                    </v-chip>
                                </span>
                                <span class="truncate-list" v-else>
                                    {{ data.item.field }}
                                </span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="6"
                        ><v-text-field
                            style="width: 60%; float: right;"
                            readonly
                            :value="responsibles()"
                            :label="$t('operation-lock:responsible')"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" class="mt-15"
                        ><v-textarea
                            readonly
                            :value="getInformationTo(getOperationLockDetails)"
                            :label="$t('service-info:infoTo') + ':'"
                            no-resize
                            rows="1"
                            auto-grow
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="mt-15"
                        ><v-textarea
                            readonly
                            :value="
                                $get(getOperationLockDetails, 'description', '')
                            "
                            :label="$t('operation-lock:description')"
                            no-resize
                            rows="1"
                            auto-grow
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    computed: {
        ...mapGetters(['getOperationLockDetails']),
    },
    methods: {
        responsibles() {
            if (
                this.getOperationLockDetails.responsible &&
                this.getOperationLockDetails.responsible.length > 0
            )
                return this.getOperationLockDetails.responsible
                    .map(el => {
                        return `${el.name} ${el.lastname}`
                    })
                    .reduce((a, b) => `${a}, ${b}`)
            return this.$t('global:empty')
        },
        getInformationTo(items) {
            const informations = [...items.to.users, ...items.to.departments]
            return informations
                ? informations.map(
                      (el, index) =>
                          `${index > 0 ? ' ' : ''}${this.$get(el, 'name', '')}${
                              el.lastname ? ' ' : ''
                          }${this.$get(el, 'lastname', '')}`
                  )
                : ''
        },
    },
}
</script>
